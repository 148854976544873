$(document).ready(function(){

  $('.m-menu').click(function(){
    $('html, body').toggleClass('noscroll');
    $('body').toggleClass('m-menu-open');
  });

  $(".swiper").fitVids();

  $('.search-btn').click(function(e){
    if(!$('.search-form').hasClass('open')){
      e.preventDefault();
      $('.search-form').addClass('open');

    }
  })
});